
import { defineComponent } from 'vue'
import { uuid } from 'vue-uuid'

export default defineComponent({
  name: 'AppBody',
  props: {
    msg: String
  },
  data () {
    return {
      isLoading: false,
      newUuid: uuid.v4()
    }
  },
  methods: {
    generateNewUuid () {
      this.isLoading = true
      setTimeout(() => {
        this.newUuid = uuid.v4()
        this.isLoading = false
      }, 1000)
    }
  }
})
